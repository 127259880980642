module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f6f7df1441742157a69423623aaa7f16"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"mode":"async","enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Roboto","file":"https://fonts.googleapis.com/css?family=Roboto:300,400,700&display=swap"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-fontawesome-css/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","it","es","fr","pt","de"],"defaultLanguage":"en","fallbackLanguage":"en","generateDefaultLanguagePage":true,"siteUrl":"https://www.aidacloud.com","verbose":true,"redirect":true,"i18nextOptions":{"defaultNS":"common","supportedLngs":["en","it","es","fr","pt","de"],"preload":["en","it","es","fr","pt","de"],"fallbackLng":"en","fallbackNS":"common","interpolation":{"escapeValue":false},"debug":false},"pages":[{"matchPath":"/app","languages":["en"]}]},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://50b4c613fc8749c5a1a6d92e254e1d2a@sentry.xhecom.net/20","sampleRate":1,"release":"16333"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
